export default {
  "admin": {
    "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De tous les temps"])},
    "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AP"])},
    "config": {
      "canChangeWifiSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les paramètres Wi-Fi ?"])},
      "confirm": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer cette configuration ?"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete config"])}
      },
      "omada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un fichier omada"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config"])},
      "titleModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une configuration"])},
      "unifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un fichier unifi"])}
    },
    "controllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôleurs"])},
    "customer": {
      "addCustomerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises"])},
      "addCustomersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une entreprise"])},
      "addLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["créer un emplacement"])},
      "addLocationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
      "editDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails"])},
      "inviteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
      "linkStripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers Stripe"])},
      "locationUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation inconnue"])},
      "manageLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les liens"])},
      "manageUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gérer les utilisateurs"])},
      "newLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'emplacement créé aura la même adresse que celle saisie pour le client"])},
      "noCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune entreprise trouvée"])},
      "noInvites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'invitations"])},
      "noLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun lieu trouvé"])},
      "noUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur"])},
      "selectCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une entreprise"])},
      "selectCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une entreprise"])},
      "selectExistingCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une entreprise existante"])},
      "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une location"])},
      "selectPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un forfait"])},
      "selectProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le fournisseur"])},
      "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le type de localisation"])},
      "stripeLinked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe lié"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
      "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer ceci ?"])},
      "freeTier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free tier"])},
      "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
      "selectCaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez si cet emplacement est CaaS"])},
      "caas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectivity as a Service (CaaS)"])},
      "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer le mode démo (par exemple PoC)"])},
      "accountAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser l'adresse e-mail de facturation pour le compte"])},
      "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte pour amplr.cloud"])},
      "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un type de connexion"])}
    },
    "dashboard": {
      "allGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout va bien"])},
      "controllers": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllers"])}
      },
      "locations": {
        "newLocationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêt pour la configuration"])}
      },
      "portal": {
        "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portail"])}
      }
    },
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositifs"])},
    "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
    "installLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêt pour l'installation"])},
    "location": {
      "chooseConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le type de configuration"])},
      "chooseControllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les contrôleurs de réseau"])},
      "chooseHardwareVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les différents fabricants de périphériques"])},
      "chooseOmadaController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le contrôleur Omada"])},
      "choosePortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un portail existant"])},
      "chooseUnifiController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le contrôleur Unifi"])},
      "config": {
        "startConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer la configuration des contrôleurs"])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de configuration"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
        "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch"])},
        "step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Point"])},
        "wifiConfigDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette configuration ne vous permet pas de modifier les noms ou les mots de passe Wi-Fi. Veuillez continuer sans."])},
        "step5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseaux sans fil"])},
        "step6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])}
      },
      "confirm": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir archiver cet emplacement ? \nFaites attention : les données en direct seront également archivées"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement d'archivage/pause"])}
      },
      "copyPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier les paramètres du portail existant"])},
      "createNewLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouvel emplacement pour cette entreprise"])},
      "createPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un portail"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'emplacement"])},
      "existingPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portail existant"])},
      "finish": {
        "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points d'accès"])},
        "chooseEnclosure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel boîtier a été utilisé ?"])},
        "discoveredDevices": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces appareils sont actifs à cet emplacement et seront inclus dans le bon de travail."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appareils utilisés"])}
        },
        "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routeur"])},
        "noEnclosure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune boîte de patch"])},
        "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commutateurs"])},
        "chooseAccessoires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quels matériaux ont été utilisés pour la finition"])},
        "chooseAccessoire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le produit"])}
      },
      "finishConfig": {
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider l'installation"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider la facturation"])},
        "addInstaller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un programme d'installation"])}
      },
      "label": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation"])},
        "apVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AP"])},
        "gatewayVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
        "switchVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch(es)"])}
      },
      "peplinkSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les paramètres de Peplink"])},
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un portail captif"])},
      "selectApVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez client"])},
      "selectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une entreprise"])},
      "selectConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un type de configuration"])},
      "selectCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le client"])},
      "selectGatewayVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le fabricant du routeur"])},
      "selectOmadaController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un contrôleur Omada"])},
      "selectPeplinkController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un emplacement Peplink"])},
      "selectPeplinkOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner l'organisation Peplink"])},
      "selectPeplinkTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le fichier de modèle Peplink"])},
      "selectSwitchVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le fabricant du commutateur"])},
      "selectUnifiController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un contrôleur Unifi"])},
      "unknownCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconnu"])},
      "updateParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de mise à jour"])},
      "configReport": {
        "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de configuration"])},
        "sim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim présente"])},
        "internetType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'Internet"])},
        "fritz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fritz!Box présente"])},
        "vlans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VLAN"])},
        "option138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option138"])},
        "option43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option43"])},
        "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wifi check"])},
        "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portail invité"])},
        "gatewayVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le fournisseur de la passerelle"])},
        "apVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le fournisseur AP"])},
        "switchVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le fournisseur du commutateur"])},
        "devicesAdopted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositifs adoptés"])},
        "sitesLinked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sites créés et liés"])},
        "pppoe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPPOE"])}
      },
      "addConfigurationLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un rapport de configuration"])},
      "noConfigurationLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas encore de rapport de configuration pour cet emplacement"])},
      "noComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas encore de commentaires"])},
      "chooseGateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le fabricant et le contrôleur du routeur"])},
      "selectVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le fabricant"])},
      "chooseSwitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le fabricant et le contrôleur pour le commutateur"])},
      "chooseAp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le fabricant et le contrôleur du point d'accès"])},
      "diyInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le client fera l'installation lui-même"])},
      "exportConfigurationLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter le rapport de configuration"])},
      "exportInstallationLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter le journal d'installation"])},
      "exportLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport d'exportation"])},
      "noWlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun fournisseur n'est indiqué pour les points d'accès. \nLes réseaux sans fil ne peuvent pas être chargés. \nContinuez s'il vous plaît."])},
      "noInstallionLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas encore de journal d'installation pour cet emplacement."])},
      "addInstallationLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un journal d'installation"])},
      "changeLocationStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceci est une installation de bricolage"])},
      "changeLocationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom de l'emplacement est différent du nom du client"])},
      "changeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez un nom d'emplacement au lieu du nom de l'entreprise. \nUtilisez-le uniquement lorsque le nom de l'emplacement est différent du nom de l'entreprise."])},
      "restoreConfirm": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurer un emplacement. \nL'emplacement sera à nouveau visible pour le client."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation des actifs"])}
      },
      "subscription": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez l'identifiant d'abonnement de Stripe pour afficher les détails de l'abonnement à l'emplacement."])}
      },
      "vendors": {
        "modal": {
          "peplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien Peplink"])},
          "omada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien TP-Link Omada"])},
          "unifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien Unifi"])},
          "zerotier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien Zerotier"])}
        },
        "peplink": {
          "currentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID associé actuel"])},
          "newId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel identifiant sélectionné"])}
        }
      },
      "choosePeplinkController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instance Peplink"])},
      "choosePeplinkOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation Peplink"])},
      "selectPeplinkGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le groupe Peplink"])},
      "choosePeplinkGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe Peplink"])},
      "selectOmadaSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le site Omada"])},
      "chooseOmadaSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site d'Omada"])},
      "chooseVendorIds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseurs de réseau"])},
      "ppsk": {
        "currentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de profil PPSK"])},
        "currentSSID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSID lié à PPSK"])},
        "defaultVid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VLAN fixe pour les locataires"])}
      },
      "selectPpskProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le profil PPSK"])},
      "ppskProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil PPSK"])},
      "selectWlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez SSID"])},
      "ppskSsid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSID"])},
      "chooseZerotierNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un nœud Zerotier"])},
      "selectZerotierNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le nœud Zerotier"])},
      "caasHardware": {
        "modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matériel CaaS"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter au format CSV"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits alloués pour cet emplacement"])}
      },
      "fixedVlanIdPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20"])},
      "fixedVlanId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VLAN fixe pour tous les locataires"])}
    },
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
    "newLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêt pour la configuration"])},
    "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal"])},
    "switches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switches"])},
    "table": {
      "config": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "controllerUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière édition"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeur"])},
        "controllerVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version du contrôleur"])},
        "authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisé"])},
        "nodeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de niveau zéro"])},
        "nodeIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP du nœud"])},
        "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ligne"])}
      },
      "customer": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
        "alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Municipalité"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche du nom de l'entreprise"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVA"])},
        "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Aucun client trouvé.</p><p>Tentez votre chance et adaptez les filtres.</p>"])}
      },
      "location": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
        "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesspoint(s)"])},
        "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé"])},
        "customerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compagnie"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu"])},
        "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Aucun lieu n'a été trouvé.</p><p>Personnalisez votre terme de recherche, vous aurez peut-être plus de chance.</p>"])},
        "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portail"])},
        "router": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routeur"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par entreprise ou par lieu"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch(es)"])},
        "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
        "venue_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la place"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
        "startInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un rapport d'installation"])},
        "qrImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image QR"])},
        "qrLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquette QR"])},
        "printLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer l'étiquette"])},
        "viewConfigReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le rapport de configuration"])},
        "viewInstallReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le rapport d'installation"])},
        "locationDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher l'emplacement"])},
        "configReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un rapport de configuration"])},
        "startConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer la configuration"])},
        "viewBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher la facturation"])},
        "snooze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somnoler"])},
        "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de paiement"])},
        "getCaasHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répertorier le matériel CaaS"])},
        "subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé"])}
      },
      "partners": {
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par nom de partenaire"])}
      },
      "webhook": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Événement"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])}
      },
      "sales": {
        "hunterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chasseur"])},
        "venueType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de lieu"])},
        "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la place"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
        "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
        "wantsOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veut un devis"])},
        "follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'un suivi"])},
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé à"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
    "wlan": {
      "addRandomGeneratedPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer un mot de passe aléatoire"])},
      "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau privé"])},
      "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau public"])}
    },
    "partners": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les partenaires"])},
      "addModal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un partenaire"])}
      },
      "addAccessModal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un accès"])}
      }
    },
    "partner": {
      "confirm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le partenaire"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer ce partenaire ?"])}
      }
    },
    "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivé"])},
    "sales": {
      "totalVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les chasseurs aujourd'hui"])},
      "yourVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos visites"])},
      "allTimeVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visites de tous les temps"])},
      "calc": {
        "mrr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenu mensuel récurrent"])},
        "oneOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total ponctuel"])},
        "amplrSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR.setup"])},
        "hardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matériel"])},
        "hardwareCaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matériel CaaS MRR"])},
        "servicesMrr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services CaaS MRR"])}
      },
      "configCalc": {
        "devices": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter les produits pour le devis"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositifs"])}
        },
        "connections": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexions Internet"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les connexions internet souhaitées"])}
        },
        "plan": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan de services"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le plan SLA souhaité pour le calcul"])}
        },
        "installCat": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la catégorie d'installation en fonction des estimations d'installation"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie d'installation"])}
        },
        "amountVenues": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de lieux"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le nombre de lieux"])}
        },
        "addDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez des appareils en recherchant et en sélectionnant."])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du calcul"])},
        "calculation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu détaillé du calcul"])}
        }
      },
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour enregistrée avec succès"])}
      },
      "totalMrr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRM total"])},
      "totalOneOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût total unique"])}
    },
    "page": {
      "sales": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chasseurs de ventes"])}
      },
      "inventory": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventaire"])}
      },
      "products": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits AMPLR"])}
      },
      "allocated": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CaaS alloué"])}
      }
    },
    "statusModal": {
      "controllers": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvre un nouvel onglet avec l'interface du contrôleur pour cet emplacement"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôleurs cloud"])}
      }
    },
    "snoozed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répété"])},
    "customers": {
      "table": {
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taper"])}
      }
    },
    "payments": {
      "create": {
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA (plan de services)"])},
        "placeholder": {
          "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le SLA"])},
          "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une connexion Internet"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["999,99"])}
        },
        "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connexion Internet"])},
        "hasFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A un basculement"])},
        "hasPeplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possède un routeur Peplink"])},
        "internetPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet pas encore installé"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un prix Stripe"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnez le montant de l'abonnement"])},
        "caasDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appareils détectés"])}
      }
    },
    "inventory": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
      "purchasePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix ​​d'achat"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur totale"])},
      "stripeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe"])},
      "editProduct": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des produits à l'inventaire"])}
      },
      "addInventory": {
        "productsToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit qui sera ajouté à l'inventaire"])},
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à l'inventaire"])}
      },
      "addTnventory": {
        "noProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun produit à ajouter pour l'instant."])}
      },
      "addProduct": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle référence produit"])}
      },
      "form": {
        "label": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du produit du fournisseur"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de produit"])},
          "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle (ex SG2008P)"])},
          "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN/SKU"])},
          "purchasePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix ​​d'achat"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix ​​de vente"])},
          "caasPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix ​​du CaaS"])},
          "configPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix ​​de configuration si applicable"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SG2008P"])},
          "amplrName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du produit AMPLR"])},
          "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un fournisseur"])},
          "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])},
          "licencePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix ​​de licence mensuel supplémentaire"])}
        },
        "placeholder": {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un type de produit"])},
          "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])},
          "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])}
        },
        "name": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink B ONE 5G"])}
        }
      },
      "deductInventory": {
        "productsToDeduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits qui seront déduits"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déduire les produits"])}
      },
      "deductProducts": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déduire les produits de l'inventaire"])}
      },
      "amplrProductSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le produit associé à l'AMPLR"])},
      "amplrProductPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le produit..."])},
      "createProduct": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau produit d'inventaire"])}
      },
      "updateProduct": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le produit d'inventaire"])}
      },
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau produit d'inventaire"])},
      "product": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un produit AMPLR"])}
      },
      "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "productModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle de produit/Description"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "noModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun modèle"])},
      "models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des modèles"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le produit"])}
    },
    "products": {
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un produit"])}
    },
    "zerotier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nœuds de niveau zéro"])},
    "zerotierNodes": {
      "authorize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser"])},
      "authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisé"])}
    },
    "allocated": {
      "returnModal": {
        "listHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits alloués"])},
        "lostHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matériel perdu (nécessite une facturation)"])},
        "archiveLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement des archives"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner le matériel CaaS"])}
      },
      "buttons": {
        "lostBroken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdu/cassé"])}
      },
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun produit alloué trouvé, sélectionnez ou changez d'emplacement."])},
      "nothingToReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun produit n'est marqué perdu ou cassé."])}
    },
    "amplrProduct": {
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un produit amplificateur"])}
    },
    "suppliers": {
      "selectSupplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un fournisseur"])}
    },
    "portalYesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portail hier"])},
    "notsubscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'abonnement"])}
  },
  "auth": {
    "login": {
      "alreadymember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous un compte ?"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez oublié votre mot de passe ?"])},
      "forgotPasswordLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser!"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content de te revoir!"])},
      "titleMorning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour!"])},
      "titleAfternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon après-midi!"])},
      "titleEvening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonne soirée!"])},
      "body2Fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez votre code d'authentification à deux facteurs pour accéder à amplr.cloud"])},
      "title2Fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérificateur de compte"])},
      "logginIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter..."])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez vos identifiants pour accéder à votre compte"])},
      "body2FaSms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remplissez votre code d'authentification à deux facteurs pour accéder à amplr.cloud, le code a été envoyé à <strong>", _interpolate(_named("number")), "</strong>. \nVous n'avez pas reçu le code ? \nDemandez-en un nouveau !"])},
      "body2FaMail": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remplissez votre code d'authentification à deux facteurs pour accéder à amplr.cloud, le code a été envoyé à <strong>", _interpolate(_named("mail")), "</strong>. \nVous n'avez pas reçu le code ? \nDemandez-en un nouveau !"])},
      "2faSend": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Code renvoyé avec succès. \nVérifiez votre ", _interpolate(_named("method")), " !"])},
      "requestCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander un nouveau code"])}
    },
    "recover": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez oublié votre mot de passe ? Récupérez votre mot de passe en envoyant un lien de réinitialisation."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un lien"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover your password"])}
    },
    "register": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre compte pour amplr.cloud."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre langue"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez été enregistré avec succès"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre"])}
    },
    "reset": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un nouveau mot de passe pour vous connecter à amplr.cloud"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le mot de passe"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changez votre mot de passe"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérez votre connectivité"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR.cloud est conçu pour vous. \nNous voulons que vous preniez le contrôle de votre internet et de votre wifi. \nObtenez des informations, modifiez les paramètres, créez de magnifiques portails invités et bien plus encore."])}
  },
  "button": {
    "admin": {
      "addCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une entreprise"])},
      "addConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une configuration"])},
      "addCustomersToGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une entreprise"])},
      "addLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un lieu"])},
      "addLocationsCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez l'emplacement de la société"])},
      "finishInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléter"])},
      "inviteUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter un utilisateur"])},
      "printPublicLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquette d'impression"])},
      "selectCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une entreprise existante"])},
      "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un emplacement existant"])},
      "startConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer la configuration"])},
      "addLocationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un rapport de position"])},
      "locationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport de localisation"])},
      "addPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un partenaire"])},
      "addAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un accès"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
      "sales": {
        "addVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une visite"])}
      },
      "addController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un contrôleur"])},
      "inventory": {
        "addQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des produits"])},
        "addProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau produit"])},
        "deductQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déduire les produits"])}
      },
      "allocated": {
        "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner le matériel CaaS"])}
      }
    },
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecter"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "deselectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désélectionner tout"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déverrouiller"])},
    "facebookLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous avec Facebook"])},
    "googleLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous avec Google"])},
    "linkedinLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous avec LinkedIn"])},
    "location": {
      "addDeviceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau groupe"])}
    },
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer un mode de paiement"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape suivante"])},
    "payments": {
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrez la page de paiement"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak link"])}
    },
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape précédente"])},
    "printLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer l'étiquette"])},
    "printQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impimer QR"])},
    "rebootNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redémarrer le réseau"])},
    "rebootNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redémarrer maintenant"])},
    "rebooting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redémarrage en cours"])},
    "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharger l'application"])},
    "removeWebhook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le webhook"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauver"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout sélectionner"])},
    "showWebhookLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le journal"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vu"])},
    "webhookResend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer le webhook"])},
    "webhookSending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoi en cours..."])},
    "webhookTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre le test"])},
    "customize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier"])},
    "createTenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauver les locataires"])},
    "addMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un autre"])},
    "enableTwoFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer 2FA"])},
    "disableTwoFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver 2FA"])},
    "2fa": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider le code"])},
      "validating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation du code 2FA..."])}
    },
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copie"])},
    "tenants": {
      "addTenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter locataire(s)"])}
    },
    "addComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un commentaire"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "scheduleDeactivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmer la désactivation"])},
    "deactivateNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivez maintenant !"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer"])},
    "reloadList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharger"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les détails"])},
    "showErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les problèmes"])},
    "payOverDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer les factures en retard"])},
    "previewPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu du portail des invités"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appelez-nous"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurer"])},
    "showCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le client"])},
    "openStripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le compte Stripe"])},
    "editCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le client"])},
    "removeGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le groupe sélectionné"])},
    "createSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un abonnement"])},
    "createInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une facture"])},
    "showLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher l'emplacement"])},
    "salesAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une autre visite"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visites à l'export"])},
    "startAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommencer"])},
    "sales": {
      "configcalculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul de configuration"])},
      "editVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la visite"])},
      "saveNewConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le nouveau calcul"])},
      "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les modifications"])}
    },
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les modifications"])},
    "impersonate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imiter"])},
    "stopImpersonating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrêter le mode imposteur"])},
    "checkConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tester la connexion"])},
    "addController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un contrôleur"])},
    "editController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le contrôleur"])},
    "portalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner"])},
    "editClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'appareil"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "newRefferal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un autre"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafraîchir"])},
    "createNewPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un autre"])},
    "unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dissocier"])},
    "editLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le lien"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien"])},
    "addPpsk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien PPSK"])},
    "deletePpsk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer PPSK"])},
    "printZerotierLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer l'étiquette Zerotier"])},
    "releaseClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les clients fixes"])},
    "viewGuestData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les données des clients"])},
    "openLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvert en rayures"])},
    "createNewReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle réservation DHCP"])},
    "createReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle réservation DHCP"])},
    "exportData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter des données"])},
    "excell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger au format Excel (.xlsx)"])},
    "csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger au format CSV (.csv)"])},
    "clientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les informations"])},
    "viewMode": {
      "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grille"])},
      "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau"])}
    },
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la connexion"])},
    "detachSocial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver la connexion sociale"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se déconnecter"])}
  },
  "client": {
    "amountDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clients"])},
    "config": {
      "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerte"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])}
    },
    "dropdown": {
      "filterAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout"])}
    },
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "filter": {
      "allNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les réseaux"])}
    },
    "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invités"])},
    "infoIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client IP"])},
    "infoName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du client"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information sur le client"])},
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
    "table": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["options"])},
      "clientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appareil"])},
      "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ip"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'hôte"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])},
      "signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signal"])}
    },
    "wired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filaire"])},
    "wireless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans fil"])},
    "restartNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez apporté des modifications à ce client. \nVeuillez le redémarrer avant de pouvoir continuer."])},
    "hasStaticIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet appareil a une adresse IP fixe configurée."])},
    "static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients réservés"])},
    "staticIpNotMatched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse IP actuelle ne correspond pas à l'adresse IP fixe. \nRedémarrez l'appareil ou reconnectez-vous au wifi"])},
    "dhcpInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La définition d'une adresse IP fixe/statique pour un appareil garantira que l'appareil obtiendra toujours la même adresse IP. \nLa modification de l'adresse nécessitera un redémarrage de cet appareil ou la connexion au réseau sans fil approprié."])},
    "updateFixedIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations client"])},
    "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devrez redémarrer l'appareil ou vous reconnecter au bon réseau sans fil pour voir les changements."])},
    "connectedWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre appareil est actuellement connecté à ", _interpolate(_named("wlan")), ", connectez l'appareil à ", _interpolate(_named("wlanToConnect"))])},
    "clientErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certains de vos clients ont besoin de votre attention"])},
    "errorWlan": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre appareil <strong>", _interpolate(_named("device")), "</strong> est actuellement connecté à <strong>", _interpolate(_named("old")), ". \nVeuillez le connecter à <strong>", _interpolate(_named("new")), "</strong></strong>"])},
    "errorIp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'appareil <strong>", _interpolate(_named("device")), "</strong> a de nouveaux paramètres, veuillez redémarrer l'appareil."])},
    "informationStatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyez prudent lorsque vous modifiez les paramètres. \nLa modification d'une adresse IP/réseau peut avoir un impact important sur votre réseau. \nNous ne sommes jamais responsables des erreurs dues à une mauvaise configuration."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'attention"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher tous les clients configurés sur le réseau. \nLes clients configurés ont une réservation DHCP."])},
    "releaseReservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients configurés"])},
    "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients configurés"])},
    "allReservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les clients configurés avec une IP fixe"])},
    "createReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une réservation DHCP"])},
    "reservation": {
      "invalidMac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse MAC indiquée n'est pas valide."])}
    },
    "notConnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non connecté"])}
  },
  "command": {
    "reboot": {
      "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande de redémarrage donnée, redémarrage en cours..."])}
    }
  },
  "cookiepopup": {
    "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["En cliquant sur \"Accepter tous les cookies\", vous acceptez le stockage de cookies sur votre appareil pour améliorer la navigation sur le site, analyser l'utilisation du site et soutenir nos efforts de marketing. Consultez notre ", _interpolate(_list(0)), " pour plus d'informations."])},
    "modal": {
      "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter tous les cookies"])},
      "centrum": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous visitez des sites Web, ils peuvent stocker ou demander des informations dans votre navigateur. Ce stockage est souvent nécessaire pour les fonctionnalités de base du site Web. Le stockage peut être utilisé à des fins de marketing, d'analyse et de personnalisation du site, telles que sauvegarde de vos préférences. La confidentialité est importante pour nous, vous avez donc la possibilité de désactiver certains types de stockage qui ne sont pas nécessaires au fonctionnement de base du site Web. Le blocage des catégories peut avoir un impact sur votre expérience sur le site Web."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre de préférences de confidentialité"])}
      },
      "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter tous les cookies"])},
      "preferences": {
        "analytics": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces éléments aident l'opérateur du site Web à comprendre comment son site Web fonctionne, comment les visiteurs interagissent avec le site et s'il y a des problèmes techniques. Ce type de stockage ne collecte généralement pas d'informations permettant d'identifier un visiteur."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytique"])}
        },
        "default": {
          "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toujours actif"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces éléments sont nécessaires pour activer les fonctionnalités de base du site Web."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nécessaire"])}
        },
        "marketing": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces éléments sont utilisés pour diffuser des publicités plus pertinentes pour vous et vos intérêts. Ils peuvent également être utilisés pour limiter le nombre de fois où vous voyez une publicité et mesurer l'efficacité des campagnes publicitaires. Les réseaux publicitaires les placent généralement avec le l'autorisation de l'administrateur du site Web."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])}
        },
        "personalisation": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces éléments permettent au site Web de mémoriser les choix que vous faites (tels que votre nom d'utilisateur, votre langue ou la région dans laquelle vous vous trouvez) et de fournir des fonctionnalités améliorées et plus personnelles. Par exemple, un site Web peut vous fournir la météo locale ou des informations sur le trafic en stockant des informations sur votre position actuelle."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisation"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les préférences d'autorisation par catégorie"])}
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer mes préférences et fermer"])}
    },
    "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préférences"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclin"])}
  },
  "customer": {
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse de votre entreprise"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])}
    },
    "invoices": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un aperçu de vos factures"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger la facture"])},
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Aucune facture n'a été trouvée.</p>"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])},
      "table": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compagnie"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de facture"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de facturation"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État de la facture"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant dû"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])}
    },
    "payment": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un aperçu de vos modes de paiement, de vos abonnements et de vos factures en cours."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion de vos paiements"])}
    },
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises"])},
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un aperçu des informations sur votre entreprise"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
    },
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compagnie"])},
    "logo": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez votre logo"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre logo"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez votre logo afin que nous puissions personnaliser certaines pages avec votre logo"])}
    },
    "plan": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le plan du client"])}
    },
    "info": {
      "accountLocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez des factures impayées qui ont déjà dépassé la date d'échéance. \nSi ceux-ci restent impayés, nous serons contraints d'intenter une action en justice. \nSi vous avez des questions concernant ce message, veuillez nous contacter au 32 3 375 60 70."])},
      "lastWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avertissement important"])}
    },
    "stripe": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outils de facturation"])},
      "paymentPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer l'URL du portail de paiement pour le client"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir un compte client dans Stripe"])}
    },
    "create": {
      "header": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'adresse"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'entreprise"])},
        "invoicingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de facturation"])},
        "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait par défaut"])},
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])}
      },
      "accountWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous désactivez l'option de création de compte, le compte ne sera pas créé. \nMais pas non plus lié à un utilisateur/compte existant. \nSi vous souhaitez le lier à un utilisateur existant, autorisez-le et remplissez l'adresse mail de l'utilisateur enregistré."])}
    },
    "paymentMethod": {
      "toggleMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de paiement actif (cochez Stripe)"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de paiement"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez manuellement l'indicateur du mode de paiement."])}
    }
  },
  "dashboard": {
    "details": {
      "downloadQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le code QR"])}
    },
    "devices": {
      "noap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun réseau sans fil trouvé"])},
      "nogateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun gateway trouvé"])},
      "noswitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun switch trouvé"])}
    },
    "invoices": {
      "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les factures ont été payées."])}
    },
    "location": {
      "notInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore installé"])}
    },
    "locations": {
      "allLocationsInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les réseaux installés dans tous les emplacements."])},
      "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les réseaux dans tous les emplacements sont OK."])}
    },
    "notification": {
      "allSystemsDown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre réseau amplificateur est hors ligne. \nAvez-vous l'électricité ? \nOui? \nVeuillez appeler <span class=\"has-text-weight-bold\">", _interpolate(_named("provider")), "</span> au <a href=\"tel:", _interpolate(_named("phone")), "\">", _interpolate(_named("phone")), "</a> !"])},
      "allSystemsRunning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre réseau amplificateur est pleinement opérationnel. \nVotre connexion Internet est fournie par <span class=\"has-text-weight-bold\">", _interpolate(_named("providerName")), "</span>."])},
      "degradedSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une partie de votre réseau n'est pas opérationnelle."])},
      "failOverActive": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le basculement est actif. Cela signifie que seuls le trafic des caisses enregistreuses, les paiements et Spotify peuvent être utilisés. Tous les réseaux invités ou autres réseaux seront temporairement indisponibles. Votre connexion est basée sur la carte SIM et est connectée au réseau ", _interpolate(_named("carrier_name")), "."])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous recueillons les informations à partir de cet emplacement."])},
      "readyForInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet emplacement n'est pas encore prêt à être utilisé. Nous attendons la fin de l'installation."])},
      "noHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons trouvé aucun matériel connecté à votre système."])},
      "powerOutage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<span class=\"has-text-weight-bold\">", _interpolate(_named("source")), "</span> a signalé une panne d'électricité dans votre région. \nLa panne a commencé le <span class=\"has-text-weight-bold\">", _interpolate(_named("startDate")), "</span> et devrait durer jusqu'au <span class=\"has-text-weight-bold\">", _interpolate(_named("endDate")), "</span> \n."])}
    },
    "publicNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceci est un réseau ouvert pour les invités"])},
    "titles": {
      "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans fil"])},
      "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients connectés"])},
      "deviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systèmes"])},
      "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
      "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures impayées"])},
      "locationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
      "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacements nécessitant une attention particulière"])},
      "locationsReadyForInstall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacements à installer"])},
      "privateNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseaux sécurisés"])},
      "publicNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseaux publics"])},
      "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switches"])},
      "wan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet"])}
    },
    "addRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une rangée"])},
    "financials": {
      "fromTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléter"])},
      "yearToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année à ce jour"])},
      "currentMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois en cours"])},
      "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mois dernier"])},
      "mrr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRR"])},
      "avgDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par jour"])}
    },
    "wlan": {
      "ppsk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce réseau utilise PPSK (Private Pre-shared Key). \nLes mots de passe peuvent être définis par utilisateur/entreprise dans l'onglet locataires."])}
    },
    "tooltips": {
      "deviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les groupes système sont un ensemble d'appareils connectés au réseau tels que des ordinateurs, des haut-parleurs, une alarme, une caméra, etc. \nCréez des groupes personnalisés pour surveiller la connexion."])}
    },
    "topology": {
      "gateway": {
        "fixedLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne fixe"])},
        "cellular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4G/5G"])},
        "wanIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP publique"])},
        "virtualWan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau étendu virtuel"])}
      },
      "unknownPublicIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconnu"])}
    },
    "wifi": {
      "privateNetworks": {
        "makeHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendre le réseau masqué"])},
        "tooltip": {
          "makeHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendre un réseau masqué a pour conséquence de ne pas voir le réseau wifi lors de la numérisation. \nVous pouvez vous connecter à un réseau caché en saisissant manuellement les détails du réseau"])},
          "broadcast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un réseau visible peut être vu par les appareils lors de la recherche de réseaux wifi. \nLorsque vous souhaitez masquer ce réseau, désactivez la diffusion. \nLa connexion à un réseau caché nécessite que vous saisissiez manuellement le nom et le mot de passe du wifi."])}
        },
        "broadcast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendre ce réseau visible (diffusion)"])}
      }
    },
    "privateNetworks": {
      "isHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau caché"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les paramètres Wi-Fi"])},
      "label": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du Wi-Fi"])}
      }
    }
  },
  "datepicker": {
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce mois-ci"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette année"])}
  },
  "error": {
    "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas accès à ce contenu."])},
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non trouvé"])},
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite sur le serveur"])},
    "apiThrottled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez récemment demandé un lien de réinitialisation."])},
    "auth": {
      "invalidResetToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le jeton de réinitialisation est incorrect."])},
      "sameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas."])},
      "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce compte n'existe pas."])},
      "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe est incorrect."])},
      "googleLoginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une connexion générale avec Google a eu lieu. \nVeuillez réessayer ou vous connecter avec votre email et votre mot de passe."])}
    },
    "deviceOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'appareil n'est pas connecté"])},
    "file": {
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce fichier est trop volumineux pour être téléchargé. La taille de fichier maximale prise en charge est de 500 Ko."])}
    },
    "generalError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé"])},
    "image": {
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette image est trop volumineuse pour être téléchargée. La taille de fichier maximale prise en charge est de 500 Mb."])}
    },
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La validation de l'e-mail a échoué. Veuillez entrer une adresse e-mail valide."])},
    "invalidPublicId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant public non valide"])},
    "messageLoadingLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le chargement prend plus de temps que la normale..."])},
    "noClientsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun client trouvé."])},
    "noDeviceGroupsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe trouvé."])},
    "noDevicesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun dispositif trouvé."])},
    "noInvitesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune invitation trouvée"])},
    "noPrivateNetworkFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun réseau privé trouvé"])},
    "noPublicId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups, l'URL n'est pas correcte."])},
    "noPublicNetworkFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun réseau public trouvé"])},
    "noSitesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun site trouvé"])},
    "noUserDataFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée trouvée"])},
    "noUsersFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruikers gevonden"])},
    "portal": {
      "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe n'est pas correct."])}
    },
    "portalClient": {
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas pu trouver votre adresse e-mail. Cela signifie que nous n'avons enregistré aucune donnée vous concernant."])}
    },
    "sessionExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre session a expiré et vous avez été déconnecté. Vous devez vous reconnecter."])},
    "sitesNnone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun site trouvé"])},
    "userExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitations non envoyées.<br/>Les utilisateurs suivants ne peuvent pas être invités. Supprimez-les de la liste et réessayez."])},
    "value": {
      "isEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer une valeur"])},
      "notDigits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur n'est pas valide"])},
      "notInRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur n'est pas dans l'intervalle"])}
    },
    "noPermissionsSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner au moins 1 autorisation pour chaque colonne."])},
    "noTenantsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun locataire trouvé"])},
    "tenants": {
      "csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le csv n'était pas au bon format"])}
    },
    "loginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, votre tentative de connexion a échoué. Veuillez vérifier vos identifiant et mot de passe puis ré-essayer."])},
    "2fInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification à deux facteurs fourni n'était pas valide."])},
    "config": {
      "configId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une configuration"])},
      "choosePeplinkSn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez le numéro de série peplink"])},
      "chooseController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un controller"])},
      "chooseVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un fournisseur"])},
      "wlansName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez tous les noms"])},
      "plan": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En fonction du nombre d'appareils, vous devez sélectionner le forfait suivant : ", _interpolate(_named("plan")), " ou supérieur"])}
    },
    "partners": {
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun partenaire trouvé"])}
    },
    "noAccessFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun accès trouvé"])},
    "noLocationsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun lieu trouvé"])},
    "noGroupsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe trouvé"])},
    "no": {
      "link": {
        "access": {
          "found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas pu vous enregistrer car le lien que vous avez utilisé n'est plus valide."])}
        }
      }
    },
    "vat": {
      "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce numéro de TVA existe déjà."])},
      "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce numéro de TVA existe déjà."])},
      "stripeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur Stripe : numéro de TVA UE incorrect fourni. \nVeuillez utiliser un numéro de TVA légal."])}
    },
    "invalidSubscriptionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID d'abonnement invalide."])},
    "subscriptionNotLinkedToCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ID d'abonnement fourni n'est pas associé au client lié à cet emplacement."])},
    "couldNotConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de se connecter au contrôleur. \nVeuillez vérifier les détails tels que l'URL/IP, l'utilisateur et le mot de passe dans l'onglet Informations."])},
    "noUserFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur ne peut être trouvé avec cette adresse Google. \nVeuillez réessayer."])},
    "google": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur générale s'est produite lors de la connexion avec votre compte Google. \nVeuillez réessayer ou utiliser votre email et votre mot de passe."])},
      "noUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur trouvé lié à ce compte Google. \nVeuillez utiliser un compte existant ou vous connecter avec votre e-mail et votre mot de passe."])}
    },
    "microsoft": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur générale s'est produite lors de la connexion avec votre compte Microsoft. \nVeuillez réessayer ou utiliser votre email et votre mot de passe."])}
    },
    "googleAlreadyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet e-mail Google est déjà enregistré auprès d'un autre utilisateur de la plateforme."])},
    "googleIdLinkedToAnotherAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce compte Google est déjà lié à un autre utilisateur de la plateforme."])},
    "microsoftAccountExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il existe déjà une connexion sociale attachée à ce compte. \nVeuillez utiliser votre compte Microsoft pour vous connecter."])},
    "googleAccountExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il existe déjà une connexion sociale attachée à ce compte. \nVeuillez utiliser votre compte Google pour vous connecter."])},
    "useSocialLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La connexion avec e-mail et mot de passe est désactivée. \nVeuillez indiquer votre identifiant Google ou Microsoft ci-joint."])},
    "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups, nous n'avons pas trouvé d'utilisateur avec cette adresse e-mail."])},
    "noPendingTenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune création de nouveaux locataires n'est en attente."])}
  },
  "errorPage": {
    "404": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hmmm... cette page n'existe plus ou a été déplacée."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas trouvé"])}
    },
    "maintenance": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous nous excusons pour le dérangement. Nous serons bientôt de retour en ligne."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'application est momenteel offline pour onderhoud."])}
    }
  },
  "legelEntities": {
    "france": {
      "scop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société Coopérative et Participative"])},
      "sa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société Anonyme (SA)"])},
      "sarl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société à responsabilité limitée (SARL)"])},
      "sas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société par Actions Simplifiée "])},
      "sci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société Civile Immobilière"])}
    },
    "belgium": {
      "nv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société anonyme (NV)"])},
      "bv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise privée (BV)"])},
      "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise Coopérative (CV)"])},
      "vof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société en nom collectif (VOF)"])},
      "commv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société en commandite (Comm V)"])},
      "vzw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association à but non lucratif (VZW)"])},
      "ivzw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association internationale à but non lucratif (IVZW)"])},
      "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenariat"])},
      "vme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association des copropriétaires (VME)"])}
    },
    "netherland": {
      "e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise individuelle"])},
      "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenariat"])},
      "v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondation"])},
      "vve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association des copropriétaires"])}
    }
  },
  "form": {
    "field": {
      "legalEntity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une entité juridique"])},
      "placeholder": {
        "simNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["00000000000000000000"])},
        "internetType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un type d'internet"])},
        "controllerIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0.0.0.0"])},
        "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le fournisseur"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doe"])},
        "pppoeUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur PPPOE"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*********"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un fichier"])},
        "statusLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lightspeed"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["info", "@", "amplr.be"])},
        "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.amplr.be"])},
        "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un partenaire"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokeren"])},
        "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9160"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieptestraat 2"])},
        "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BE0768.611.469"])},
        "downloadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50"])},
        "uploadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50"])},
        "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["127.0.0.1"])},
        "deviceGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lightspeed"])},
        "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amplir"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amplir"])},
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jean"])},
        "legalEntity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une entité juridique"])},
        "searchProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher des produits"])},
        "searchLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de recherche"])},
        "subscriptionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnez l'identifiant d'abonnement : sub_xxxxxxxxx"])},
        "subscriptionItemId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnez l'identifiant de l'article d'abonnement : si_xxxxx"])},
        "controllerAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon premier cloudcontroller"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3247777777"])},
        "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambre/espace"])},
        "mailchimpAudiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre audience Mailchimp"])},
        "ipSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["199"])}
      },
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["langue"])},
      "controllerIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller IP"])},
      "amountGateways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant des passerelles"])},
      "amountAp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant AP"])},
      "amountSwitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commutateur de montant"])},
      "pppoeUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur PPPOE"])},
      "pppoePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe PPPOE"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
      "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les partenaires"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boîte"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])},
      "sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un secteur"])},
      "subnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les réseaux"])},
      "endDatePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la date/l'heure"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date/heure de fin"])},
      "startDatePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la date/l'heure"])},
      "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date/heure de début"])},
      "nameInstaller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'installateur"])},
      "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la localisation"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue"])},
      "configName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la configuration"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
      "customerAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
      "downloadSpeedFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse de téléchargement du basculement"])},
      "placholder": {
        "downloadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20"])}
      },
      "freeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commentaires"])},
      "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez le mot de passe"])},
      "passwordNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
      "peplinkOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation Peplink"])},
      "peplinkSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink série"])},
      "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])},
      "searchNetworkDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un périphérique réseau"])},
      "uploadSpeedFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse de chargement du basculement"])},
      "verifyBeforeDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter l'étiquette ci-dessus"])},
      "locationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de lieu"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les espaces"])},
      "providerid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du fournisseur"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
      "deviceGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du groupe système"])},
      "downloadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse de téléchargement"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
      "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse IP"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
      "simNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro SIM"])},
      "statusLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquette"])},
      "uploadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse de téléchargement"])},
      "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro de TVA"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site web"])},
      "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Postal"])},
      "subscriptionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant d'abonnement Stripe"])},
      "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de connexion"])},
      "hasFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A un failover"])},
      "subscriptionItemId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de l'article d'abonnement"])},
      "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le fournisseur du contrôleur"])},
      "tooltip": {
        "controllerUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournissez l’URL complète du contrôleur avec le port si nécessaire. \nEx : https://votredomaine.com:8443 ou https://193.12.123.12:8443."])},
        "controllerAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnez un alias à votre contrôleur pour référence interne. \nSi laissé vide, une chaîne aléatoire sera générée."])}
      },
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
      "controllerUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL du contrôleur"])},
      "controllerAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​du contrôleur"])},
      "replyTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse par email à l'adresse"])},
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambre/espace"])},
      "providerSatisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satisfaction des prestataires"])},
      "providerSatisfactionFreeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi le client est-il satisfait"])},
      "providerIssuesReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une raison"])},
      "selectFollowUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le suivi"])}
    },
    "fileInput": {
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le fichier"])}
    },
    "select": {
      "selectDeviceGroupType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de système"])}
    },
    "label": {
      "selectNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À quel réseau appartient cet appareil ?"])},
      "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnez un alias à l'appareil"])},
      "useStaticIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser une IP statique/fixe"])},
      "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souviens-toi de moi"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
      "hasFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'emplacement aura une connexion de basculement"])},
      "connectionTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de connexion"])},
      "uniformBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lier un élément spécifique de l'abonnement"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la place"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Postal"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
      "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
      "contactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne de contact"])},
      "chooseProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez votre fournisseur d'accès Internet actuel"])},
      "posSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système de point de vente"])},
      "canUseFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilise les services de livraison/cloud/..."])},
      "terrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A une terrasse"])},
      "wantsOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veut un devis"])},
      "venueSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une taille"])},
      "walkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'était une promenade"])},
      "notInterested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas intéressé"])},
      "config": {
        "failover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution de basculement"])},
        "router": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routeur"])},
        "isPeplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routeur Peplink"])},
        "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch"])},
        "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point d'accès"])},
        "amountVenues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de lieux"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan de services"])},
        "installCat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie d'installation"])},
        "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connexion Internet"])},
        "extraPeplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant licence spéciale Peplink (bruto)"])},
        "hasSpecialPeplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possède une licence spéciale Peplink"])},
        "maxOneOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant maximum pour un coût unique"])},
        "fixedIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP fixe"])}
      },
      "variantCaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s'agit de la connectivité en tant que service"])},
      "visit": {
        "interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intéressé"])},
        "marketingOptIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentement pour les mails/marketing"])},
        "knowsAmplr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connaît déjà l'amplir"])}
      },
      "router": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routeur"])},
      "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commutateur(s)"])},
      "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points d'accès"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
      "needsFollowUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'un suivi"])},
      "contactPersonPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone de la personne à contacter"])},
      "contactPersonEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel de la personne à contacter"])},
      "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la place"])},
      "deviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'appareil"])},
      "selfOrdering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A un kiosque"])},
      "takeOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A emporter/livraison"])},
      "satisfactionScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de satisfaction"])},
      "providerIssuesReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison pour laquelle je suis mécontent"])},
      "followUpMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesures de suivi"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel est le rôle du porte-parole"])},
      "nodeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du nœud"])},
      "nodeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du nœud"])},
      "mac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse MAC"])}
    },
    "placeholder": {
      "selectNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le réseau"])},
      "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un type de connexion"])},
      "posSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un système de point de vente"])},
      "venueSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille du lieu"])},
      "config": {
        "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un type de connexion"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un plan SLA"])},
        "installCat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir la catégorie d'installation"])}
      },
      "sales": {
        "loadExisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger un calcul existant"])}
      },
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un rôle"])}
    },
    "noResults ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé. \nEssayer à nouveau."])}
  },
  "general": {
    "connectionRestored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons de nouveau détecté une connexion Internet active sur votre appareil. Vous pouvez continuer à utiliser amplr.cloud ! Appuyez sur Actualiser ou attendez que la fenêtre disparaisse."])},
    "noConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes pas connecté à Internet. Assurez-vous d'avoir une connexion Internet active pour utiliser amplr.cloud."])},
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hors ligne"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ligne"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacements"])},
    "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégrations"])}
  },
  "group": {
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])},
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
    "partners": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les partenaires"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez ajouter ou supprimer vos partenaires ici"])}
    }
  },
  "groupProfile": {
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse du groupe"])}
    },
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un aperçu des détails de votre groupe"])}
    }
  },
  "history": {
    "table": {
      "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesspoint(s)"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "failover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failover"])},
      "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
      "noFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de failover"])},
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Aucune donnée n'a été trouvée.</p><p>Ajustez votre terme de recherche, vous aurez peut-être plus de chance.</p>"])},
      "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switches"])}
    }
  },
  "info": {
    "listening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de demandes..."])},
    "enableTwoFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurisez votre compte avec une authentification à deux facteurs. \nUtilisez l'application Google Authenticator ou votre gestionnaire de mots de passe préféré."])},
    "twoFactorActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte est sécurisé par une authentification à deux facteurs."])},
    "loadingPeplinkGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez patienter pendant que nous chargeons tous les groupes Peplink liés à l'organisation sélectionnée."])}
  },
  "location": {
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse du lieu"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])}
    },
    "deviceGroups": {
      "addDeviceGroupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau groupe système"])},
      "addFirstDeviceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre premier groupe"])},
      "addMoreDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter d'autres appareils"])},
      "connectedClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Périphériques réseau actifs"])},
      "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des appareils à votre groupe"])},
      "devicesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez au moins 1 appareil à ajouter à votre groupe"])},
      "editModal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ici, vous pouvez modifier le nom et le type de votre groupe système"])},
        "deviceBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter ou supprimer des appareils de votre groupe"])},
        "deviceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour les appareils du groupe"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de groupe"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le groupe système"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de groupe"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnez un nom à votre groupe"])},
      "newDeviceGroup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cool! Nous avons créé un nouveau groupe système (", _interpolate(_named("name")), "), afin que vous puissiez vérifier si les appareils du groupe système sont en ligne."])},
      "newDeviceGroupList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Périphériques du groupe système"])},
      "notification": {
        "deviceGroupCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nouveau groupe d'appareils a été créé"])},
        "deviceGroupUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le groupe des appareils a été mis à jour"])}
      },
      "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des informations sur le groupe"])},
      "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des appareils"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un type pour votre groupe"])},
      "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer ce groupe d'appareils ?"])}
    },
    "installation": {
      "bridge": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y a-t-il un bridge présent ?"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites un choix"])}
      },
      "failover": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existe-t-il un failover ?"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites un choix"])}
      },
      "guest_wifi": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est-ce que le wifi invité est OK ?"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites un choix"])}
      },
      "images": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez des images de l'installation"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger des images"])}
      },
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])}
    },
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacements"])},
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu"])},
    "updateWlan": {
      "form": {
        "fields": {
          "verifyBeforeSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer pour modifier"])}
        }
      },
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous modifiez les paramètres de ce réseau wifi, tous les appareils déjà connectés perdront leur connexion et devront être reconnectés avec les nouveaux paramètres."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifiez les paramètres Wi-Fi pour :"])},
      "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous toujours modifier les paramètres ? \nConfirmez en saisissant le nom du réseau wifi."])}
    },
    "connection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la connexion pour l'emplacement"])}
    },
    "create": {
      "header": {
        "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la localisation"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexions Internet"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de lieu"])}
      },
      "customerAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser l'adresse du client comme adresse de localisation"])}
    },
    "createSubscription": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un abonnement"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez un nouvel abonnement Stripe pour cet emplacement. \nVous serez redirigé vers Stripe."])}
    },
    "createInvoice": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez une facture pour des coûts ou des interventions ponctuels."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une facture"])}
    },
    "billing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'abonnement"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouvez plus de détails sur l’abonnement de localisation ici."])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestations de service"])},
      "serviceBilledButNotActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le service est facturé mais n'est pas actif sur place"])},
      "serviceNotBilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le service est actif sur place mais n'est pas facturé."])}
    },
    "plan": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan de services"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez le plan de service pour cet emplacement."])}
    },
    "config": {
      "unifiOs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est un emplacement du système d'exploitation Ubiquiti"])}
    },
    "topology": {
      "vpn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fusionhub"])},
      "tooltip": {
        "vpn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexions Fusionhub disponibles entre cet emplacement et le hub."])}
      },
      "client": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse IP"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du client"])},
        "connectionDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auge connectée"])},
        "connectedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté à"])},
        "connectedPort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port"])},
        "wired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch"])},
        "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans fil"])}
      }
    },
    "tenants": {
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création en attente"])}
    }
  },
  "locationProfile": {
    "commands": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exécuter des fonctions sur votre réseau"])},
      "reboot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redémarrer tous les périphériques réseau"])},
      "rebootNetwork": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous redémarrez tous les périphériques réseau, il n'y aura temporairement pas d'Internet à cet endroit. Le redémarrage de ces périphériques peut prendre quelques minutes. Êtes-vous sûr ?"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redémarrer tous les périphériques réseau"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre de commande"])}
    },
    "hours": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez vos heures d'ouverture ici"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures d 'ouverture"])}
    },
    "info": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails supplémentaires sur votre emplacement"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])}
    },
    "qr": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générez un code QR public"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un code QR public permet à vos employés de retrouver rapidement l'état du réseau. Incorporez le code QR dans vos procédures ou ou placez-le sur le site."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support QR"])}
    },
    "service": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En fonction de votre plan de service, vous pouvez utiliser les services suivants"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])}
    }
  },
  "locations": {
    "table": {
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Aucun lieu n'a été trouvé.</p><p>Adaptez votre terme de recherche, peut-être aurez-vous plus de chance.</p>"])},
      "qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le code QR"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
      "siteName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site"])},
      "sitePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])}
    },
    "payment": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un aperçu de vos modes de paiement, abonnements en cours et factures."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérez vos paiements"])}
    },
    "installed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
    "notInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas installé"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
    "tenants": {
      "createNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer maintenant"])},
      "forceCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande d'envoi pour créer un locataire."])}
    }
  },
  "modalAdminAddCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une société"])},
  "mydata": {
    "download": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez télécharger vos données, veuillez saisir votre adresse e-mail ci-dessous."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger les données"])}
    },
    "optout": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez que nous supprimions vos données, veuillez saisir votre adresse e-mail ci-dessous."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suprimmer les données"])}
    }
  },
  "nav": {
    "account": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])}
    },
    "admin": {
      "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises"])},
      "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
      "controllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllers"])},
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieux"])},
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion"])},
      "sales": {
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
        "createVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une visite"])},
        "configCalculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculateur de configuration"])}
      },
      "inventory": {
        "inventory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventaire"])},
        "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits AMPLR"])},
        "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journaux"])},
        "allocatedCaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CaaS alloué"])}
      }
    },
    "customer": {
      "b2b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2B"])},
      "b2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2C"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])},
      "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacements"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compagnie"])},
      "titleB2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page d'état"])},
      "branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l'image de marque"])}
    },
    "dashboard": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
    },
    "deviceGroups": {
      "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appareils"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["infos"])}
    },
    "group": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])}
    },
    "locations": {
      "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipement"])},
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "deviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systèmes"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histoire"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invités"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacements"])},
      "topology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup"])},
      "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendors"])},
      "installationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport d'installation"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page d'état"])},
      "tenantsManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locataires"])},
      "configReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport de configuration"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])}
    },
    "portal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portail invité"])}
    },
    "users": {
      "invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitations"])},
      "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])}
    },
    "partner": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
    },
    "invites": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])}
    },
    "access": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès"])}
    },
    "button": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])}
    },
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
    "integrations": {
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])}
    }
  },
  "noAccess": {
    "body": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malheureusement, vous n'avez pas accès à cette page."])},
      "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malheureusement, vous n'avez pas accès à cette page. Veuillez mettre à niveau votre forfait pour accéder à cette page."])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'accès"])},
    "titleNoPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'accès. Veuillez saisir votre mot de passe."])}
  },
  "noPlan": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez avoir un plan supérieur pour accéder à cette page. Contactez-nous pour les possibilités."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Améliorez votre forfait"])}
  },
  "notPayed": {
    "body": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte est temporairement désactivé. \nVeuillez contacter l'administrateur de votre entreprise pour compléter les informations de paiement."])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'accès"])}
  },
  "notification": {
    "customer": {
      "noPaymentMethod": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aucun mode de paiement n'est actuellement défini pour ", _interpolate(_named("customerName")), ". \nPour utiliser la plateforme, vous devez ajouter un mode de paiement valide. \nSi vous rencontrez des problèmes pour configurer cela, veuillez nous contacter."])},
      "paymentMethodSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez un mode de paiement actif. Si vous souhaitez le modifier, rendez-vous sur la page de paiement."])}
    },
    "demoMode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bonjour ", _interpolate(_named("name")), ", vous consultez amplr.cloud en mode démo. \nPas de soucis, aucune fonctionnalité n'est cachée ! \nSi vous avez des questions concernant la plateforme. \nVeuillez contacter support", "@", "amplr.be !"])},
    "snooze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de répétition mis à jour"])},
    "imposter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous usurpez actuellement l'identité de <strong>", _interpolate(_named("name")), "</strong>. \nSi vous souhaitez quitter le mode imposteur, appuyez sur le bouton stop !"])},
    "referral": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'avoir référé ! \nNous prendrons contact avec votre prospect et vous tiendrons au courant. \nVous pouvez ajouter une autre référence si vous le souhaitez !"])}
    },
    "exportStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'exportation a commencé. \nVeuillez vérifier votre boîte aux lettres pour le résultat final."])}
  },
  "openingHours": {
    "createBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez des heures d'ouverture pour ce lieu. Vous pouvez les utiliser pour mettre votre portail invité hors ligne lorsque l'entreprise est fermée."])},
    "error": {
      "timePerDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une heure d'ouverture et de fermeture pour chaque journée portes ouvertes."])},
      "times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une heure d'ouverture et de fermeture."])}
    },
    "everyDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Même horaire tous les jours"])},
    "fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ven"])},
    "mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lun"])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas encore d'heures d'ouverture"])},
    "sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sam"])},
    "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dim"])},
    "thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeu"])},
    "tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mar"])},
    "wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mer"])}
  },
  "pagination": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])}
  },
  "payments": {
    "methods": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez un lien pour configurer une méthode SEPA/carte de crédit afin que nous puissions facturer les clients de manière récurrente."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthodes de paiement"])}
    },
    "caasPrice": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un prix CaaS"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez un nouveau priceId dans Stripe pour un emplacement avec connectivité en tant que service."])}
    },
    "stepList": {
      "openStripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrez ce lien Stripe pour créer un abonnement"])},
      "pasteProductId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collez ce priceId dans le champ de recherche de produit"])},
      "metaData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez des métadonnées, utilisez « siteId » comme clé et collez le locationId suivant dans la valeur"])},
      "invoiceMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collez la valeur suivante dans le champ Mémo de facture (sous avancé)"])},
      "createSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appuyez sur le bouton « Créer un abonnement » pour terminer."])}
    },
    "createInvoice": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle facture"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez la création de la facture à partir d'ici, afin que la facture puisse être liée à l'emplacement."])}
    }
  },
  "permissions": {
    "customer_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donne à l'utilisateur la permission d'afficher les données de l'entreprise."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher la société"])}
    },
    "customer_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donne à l'utilisateur la permission de modifier les détails de l'entreprise."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer d'entreprise"])}
    },
    "group": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])}
    },
    "group_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donne à l'utilisateur la permission d'afficher les données du groupe."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le groupe"])}
    },
    "group_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donne à l'utilisateur la permission de modifier les données du groupe."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le groupe"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])}
    },
    "invoices_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donne à l'utilisateur la permission d'afficher les informations de facturation."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les factures"])}
    },
    "invoices_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donne à l'utilisateur la permission de modifier les informations de facturation."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les factures"])}
    },
    "location": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement"])}
    },
    "location_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donne à l'utilisateur la permission d'afficher les données de localisation."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher l'emplacement"])}
    },
    "location_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donne à l'utilisateur la permission de modifier les informations de localisation."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'emplacement"])}
    },
    "portal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portail"])}
    },
    "portal_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donne à l'utilisateur la permission d'afficher les données du portail."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le portail"])}
    },
    "portal_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donne à l'utilisateur la permission de modifier les données du portail."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de portail"])}
    },
    "users": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])}
    },
    "users_create": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donne à l'utilisateur la permission d'ajouter des utilisateurs à des groupes, entreprises ou emplacements spécifiques."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des utilisateurs"])}
    },
    "users_delete": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donne à l'utilisateur la permission de supprimer des utilisateurs"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer des utilisateurs"])}
    },
    "users_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donne à l'utilisateur la permission d'afficher les données de l'utilisateur."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les utilisateurs"])}
    },
    "users_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donne à l'utilisateur la permission de modifier les données de l'utilisateur."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les utilisateurs"])}
    },
    "noAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes pas autorisé à effectuer des actions."])},
    "customer": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])}
    }
  },
  "policy": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>En vous connectant à ce réseau d'invités, vous reconnaissez avoir lu, compris et accepté d'être lié par le présent accord. Ce réseau d'invités est fourni par les propriétaires et est à leur seule discrétion. Votre accès au réseau peut être bloqué, suspendu ou interrompu à tout moment et pour n'importe quelle raison. Vous acceptez de ne pas utiliser le réseau sans fil à des fins illégales et vous assumez l'entière responsabilité de vos actes. Le réseau sans fil est fourni 'en l'état' sans garantie d'aucune sorte, expresse ou implicite.</p><p>Pour établir la connexion de ce réseau invité, l'adresse mac du client à connecter est utilisée et stockée.</p><p>En aucun cas, AMPLR BV ne peut être tenu responsable de l'utilisation de ce réseau wifi.</p><p>AMPLR BV peut conserver des données si cela est demandé lors de la connexion au réseau. En aucun cas, ces données ne seront utilisées à des fins de marketing, sauf si l'utilisateur en donne l'autorisation (opt-in).</p><p>Si l'utilisateur souhaite consulter/modifier ou supprimer ses données, il peut nous contacter en envoyant un courriel à dataprotection[at]amplr.be</p>"])},
    "optout": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez consulter ou supprimer vos données de notre base de données, cliquez sur le bouton ci-dessous."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes données"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher et supprimer des données"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions"])},
    "titleOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bureau"])}
  },
  "portal": {
    "body": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Wi-Fi public est actuellement indisponible. Veuillez réessayer plus tard."])},
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes actuellement fermés."])},
      "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cliquant sur se connecter, vous acceptez nos"])},
      "policyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termes et conditions"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href=\"https://www.amplr.be\" target=\"_blank\" class=\"has-text-primary has-text-weight-bold\">amplr</a> offre une connexion Wi-Fi gratuite, sécurisée et rapide à cet endroit. Appuyez sur Connecter et commencez à naviguer."])},
      "connecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sécurisons votre connexion..."])},
      "trusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1000+ lieux utilisent <a href=\"https://www.amplr.be\" target=\"_blank\" class=\"has-text-primary has-text-weight-bold\">amplr</a>"])}
    },
    "client": {
      "email": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre adresse e-mail"])}
      },
      "emailOptIn": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes données peuvent être utilisées à des fins de marketing."])}
      },
      "firstname": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre prénom"])}
      },
      "lastname": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre nom de famille"])}
      },
      "phonenumber": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre numéro de téléphone"])}
      },
      "premiumWifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Opt-in entraînera un wifi plus rapide)"])},
      "forceOptIn": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour utiliser le wifi gratuit, je reconnais que mes données peuvent être utilisées à des fins de marketing."])}
      },
      "emailOptInPiggy": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes données pourront être utilisées à des fins de marketing et de fidélisation."])}
      }
    },
    "overview": {
      "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des invités connectés de tous les temps"])},
      "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les invités connectés aujourd'hui"])},
      "todayOptIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt-in marketing aujourd'hui"])}
    },
    "settings": {
      "advancedSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres avancés"])},
      "analytics": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez un identifiant Google Tag Manager ou Facebook Pixel pour ajouter un suivi à votre portail"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytique"])}
      },
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, vous modifiez les paramètres généraux de l'entreprise"])},
      "data": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurez ici les données que vous souhaitez collecter auprès de l'utilisateur."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les données"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecter de données"])}
      },
      "design": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisez les images, les couleurs et le texte de votre portail."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer la page"])}
      },
      "form": {
        "analyticsFacebook": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook Pixel ID"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123456789123456"])}
        },
        "analyticsGoogle": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Tag Manager ID"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GTM-ABC1234"])}
        },
        "analyticsPrivacyPolicy": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy URL"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.amplr.be/privacy-policy"])}
        },
        "background": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image de fond (jpg, png)"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choisir un fichier"])}
        },
        "bgColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur de fond"])}
        },
        "body": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser le corps du texte"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le texte du corps"])}
        },
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer la page"])},
        "buttonText": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personnaliser le texte du bouton"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le texte du bouton"])}
        },
        "client": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si décoché, l'utilisateur peut se connecter sans entrer de données"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelles données souhaitez-vous collecter auprès de l'utilisateur ?"])}
        },
        "color": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir une couleur"])}
        },
        "customer": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser les paramètres de l'entreprise"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez suivre les paramètres de l'entreprise afin de ne pas avoir à configurer un portail pour chaque emplacement."])}
        },
        "disabled": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placer le portail temporairement hors ligne"])}
        },
        "disabledText": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adapter le texte hors ligne temporaire"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le texte indiquant quand le portail est temporairement hors ligne"])}
        },
        "duration": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée (min)"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par exemple 30"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez la durée pendant laquelle l'invité peut rester connecté après l'authentification."])}
        },
        "logo": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo (svg, jpg, png)"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choisir un fichier"])}
        },
        "mainColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur principale"])}
        },
        "offlineText": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser le texte hors ligne"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le texte lorsque le portail est hors ligne"])}
        },
        "password": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un mot de passe"])}
        },
        "passwordToggle": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez un mot de passe pour vous connecter"])}
        },
        "schedule": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les heures d'ouverture"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre le portail hors ligne en dehors des heures d'ouverture"])}
        },
        "secondaryColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur d'accentuation"])}
        },
        "secondaryTextColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur du texte d'accompagnement"])}
        },
        "textColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur du texte"])}
        },
        "toggle": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portail personnalisé"])}
        },
        "url": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page de destination"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. https://www.amplr.be"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez où les invités doivent se rendre après l'authentification. \nIl peut s'agir de votre site Web ou d'une autre page de destination."])}
        },
        "webhookEndpoint": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL du webhook"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://yourcustomwebhook.zapier.com"])}
        },
        "webhookSecret": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret partagé Webhook"])}
        },
        "primaryColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur primaire"])}
        },
        "forceOptIn": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forcer l’opt-in pour le marketing"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En activant cette fonction, les clients qui souhaitent se connecter doivent cocher la case relative aux finalités marketing."])}
        },
        "forceEmailValidation": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En utilisant la validation forcée des e-mails, toutes les adresses e-mail seront validées par un fournisseur tiers pour vérifier si l'adresse utilisée est légitime."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer la validation de l'adresse e-mail"])}
        },
        "strictEmailValidation": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une validation stricte implique que seules les adresses ayant un score de santé de 100 seront autorisées. \nSi le score est inférieur mais toujours livrable, l'utilisateur recevra une erreur car la validation stricte est activée."])}
        },
        "strictValidation": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forcer une validation stricte"])}
        },
        "usePromoUrlPerLanguage": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page de déclenchement basée sur la langue"])}
        }
      },
      "general": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisez les paramètres généraux de votre portail ici."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])}
      },
      "login": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez ici comment vous souhaitez qu'un utilisateur se connecte à votre WiFi public. Si rien n'est choisi, l'utilisateur peut se connecter sans authentification."])},
        "facebook": {
          "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre clé API Facebook"])},
          "inputSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre clé secrète de l'API Facebook"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé API Facebook"])},
          "placeholderSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé secrète de l'API Facebook"])},
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le bouton de connexion Facebook"])}
        },
        "google": {
          "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre clé API Google"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé API Google"])},
          "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarque : la connexion Google ne fonctionne pas sur le portail captif iOS"])},
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le bouton de connexion Google"])}
        },
        "linkedin": {
          "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre clé API LinkedIn"])},
          "inputSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre clé secrète de l'API LinkedIn"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé API LinkedIn"])},
          "placeholderSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé secrète de l'API LinkedIn"])},
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le bouton de connexion LinkedIn"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification"])}
      },
      "preview": {
        "desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desktop"])},
        "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobile"])},
        "tablet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tablette"])}
      },
      "schedule": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre le portail temporairement hors ligne ou mettre hors ligne en dehors des heures d'ouverture"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hors ligne"])}
      },
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos modifications ont été enregistrées"])},
      "tabs": {
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
        "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
        "layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise en page"])},
        "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Néerlandais"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte"])},
        "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemand"])}
      },
      "webhook": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez des webhooks pour déclencher des applications externes pour faire quelque chose."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks"])}
      },
      "customPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À l'heure actuelle, une page de marque amplr est utilisée comme portail wifi invité. \nActivez le portail personnalisé pour utiliser votre marque."])},
      "integrations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégrations"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tirez le meilleur parti de votre wifi invité en utilisant nos intégrations."])}
      }
    },
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les paramètres ont été enregistrés."])},
    "table": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actions"])},
      "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur du bloc"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
      "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dispositif"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adresse électronique"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse IP"])},
      "mac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse Mac"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Aucun utilisateur n'a été trouvé.</p><p>Ajustez les filtres, peut-être aurez-vous plus de chance.</p>"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par adresse email ou mac"])},
      "unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock user"])},
      "mailchimp_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut Mailchimp"])}
    },
    "integration": {
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
      "message": {
        "piggy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez le <b>programme de fidélité</b> et recevez des avantages immédiatement."])}
      }
    },
    "integrations": {
      "smartendrModal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartendr"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour utiliser cette intégration, vous devez disposer d'un abonnement Smartendr actif."])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer cette intégration"])},
        "openDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir Smartendr"])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer cette intégration"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collez la clé API dans votre compte Smartendr"])}
      },
      "piggyModal": {
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre clé API, vous pouvez la trouver dans le tableau de bord Piggy"])},
        "openDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cochon ouvert"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piggy"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour utiliser cette intégration, vous avez besoin d'un abonnement actif avec Piggy."])}
      },
      "mailchimpModal": {
        "openDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir Mailchimp"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailchimp"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez besoin d'un abonnement Mailchimp actif pour utiliser ces intégrations."])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer cette intégration"])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer cette intégration"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collez votre clé API, elle se trouve dans le tableau de bord Mailchimp"])},
        "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez votre public"])}
      }
    },
    "error": {
      "mailNotvalidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! \nOn dirait que cette adresse e-mail joue à cache-cache. \nRéessayez avec un vrai, s'il vous plaît !"])}
    }
  },
  "scheduler": {
    "buttons": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une période"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])}
    },
    "duration": {
      "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toujours"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fois au total"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
      "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jusqu'à"])}
    },
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
    "repeat": {
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du quotidien"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuel"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne répétez pas"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebdomadaire"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuel"])}
    },
    "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jusqu'à"])}
  },
  "service": {
    "customGuestPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wifi personnalisé pour les clients"])},
    "emailSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support par e-mail"])},
    "nextDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervention le lendemain"])},
    "phoneSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support téléphonique"])}
  },
  "status": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
    "notification": {
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes là pour vous aider. Vous pouvez nous joindre par téléphone."])}
    },
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
    "standby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etre prêt"])},
    "toDeploy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen devices"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aandacht"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenir les détails de l'emplacement..."])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voici l'état de votre solution internet et wifi à l'adresse :"])},
    "issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous rencontrez des problèmes avec quelque chose ?"])},
    "problems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre problème ?"])},
    "urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'un soutien urgent ?"])},
    "settings": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurez votre page d'état"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La page d'état est la page que vos employés obtiennent lorsqu'ils scannent le code QR amplr. \nVous pouvez ajouter toutes les différentes informations de support sur cette page."])},
      "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accédez à l'outil de conception de votre page d'état en cliquant sur le bouton ci-dessous"])},
      "noButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore configuré de boutons de contact"])},
      "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un bouton de contact"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiqueter"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez publier vos modifications, sinon les modifications ne seront pas reflétées en direct"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer la page"])}
    },
    "gettingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenir des détails..."])},
    "notInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas installé"])},
    "notConfigured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas configuré"])},
    "reportMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport de configuration manquant"])},
    "buttons": {
      "amplr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez amplr"])}
    },
    "liveLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["installée"])},
    "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivé"])},
    "deviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos systèmes actifs"])},
    "deviceGroupOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les appareils de ce groupe système sont en ligne !"])},
    "deviceGroupWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certains appareils de ce groupe système ne sont pas connectés pour le moment. \nS'il vous plaît, jetez un oeil!"])},
    "deviceGroupOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun des appareils du groupe système ne semble être en ligne. \nCela a un impact majeur. \nVeuillez vérifier le groupe."])},
    "deviceGroupList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu de l'appareil"])},
    "deviceOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appareil hors ligne"])},
    "deviceGroupOfflineInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet appareil n'est soit pas connecté à une source d'alimentation, soit il n'est pas connecté à Internet via un câble ou un wifi. \nVeuillez vérifier les connexions. \nIdentifiez l'appareil en fonction de l'adresse MAC ci-dessus."])}
  },
  "success": {
    "admin": {
      "config": {
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuration a été ajoutée."])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuration a été supprimée."])}
      },
      "customer": {
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'entreprise a été ajoutée"])},
        "addedCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La société a été ajoutée au groupe."])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'entreprise a été supprimée."])},
        "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'entreprise a été modifiée"])},
        "locationCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lieu a été ajouté à l'entreprise."])},
        "userAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur a été ajouté à l'entreprise."])}
      },
      "group": {
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le groupe a été ajouté"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le groupe a été modifiée"])}
      },
      "location": {
        "configCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuration a été enregistrée"])},
        "configUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuration a été modifiée."])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'emplacement a été supprimé"])},
        "infoUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les changements ont été enregistrés."])},
        "installFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les changements ont été enregistrés."])},
        "updatedVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les changements ont été enregistrés."])},
        "configReportCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le rapport a été enregistré"])},
        "commentAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commentaire a été ajouté avec succès"])},
        "commentUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre rapport a été mis à jour"])},
        "commentDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le rapport a été supprimé"])},
        "controllerLinksCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les contrôleurs sont configurés"])}
      },
      "userDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur a été supprimé."])},
      "userLinkDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien a été supprimé."])},
      "partner": {
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le partenaire a été ajouté"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le partenaire a été supprimé"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le partenaire a été mis à jour"])},
        "usersAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs ont été invités"])},
        "linkDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'invitation a été supprimée"])},
        "userDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur a été supprimé"])},
        "accessAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'accès a été ajouté"])},
        "locationDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lieu a été supprimé"])},
        "customerDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le client a été supprimé"])},
        "groupDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le groupe a été supprimé"])}
      }
    },
    "auth": {
      "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe a été modifié avec succès. Vous pouvez vous connecter."])},
      "passwordResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez votre boîte aux lettres pour le lien de réinitialisation."])}
    },
    "customer": {
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les modifications ont été enregistrées avec succès."])},
      "logoSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le logo a été enregistré"])}
    },
    "portalClient": {
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos données ont été supprimées."])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos données seront téléchargées dans quelques instants"])},
      "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous avons envoyé un e-mail."])}
    },
    "user": {
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copié dans le presse-papier"])},
      "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a été modifié avec succès"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les modifications ont été enregistrées avec succès."])}
    },
    "users": {
      "userEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les modifications sont enregistrées"])},
      "invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'invitation a été envoyée"])}
    },
    "clientDhcpRemoved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons supprimé l'adresse IP fixe de ce client."])},
    "savedDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos paramètres sont enregistrés"])},
    "statusButtonSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos paramètres sont enregistrés"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copié avec succès !"])},
    "partner": {
      "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le partenaire a été ajouté"])},
      "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le partenaire a été supprimé"])}
    },
    "referral": {
      "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre recommandation a été envoyée"])}
    },
    "poeCycled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation PoE"])},
    "portChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changements de port appliqués !"])},
    "clientSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les paramètres du client sont mis à jour avec succès."])},
    "branding": {
      "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changements sauvegardés"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image de marque créée"])}
    },
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres sauvegardés"])}
  },
  "support": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hmmm... Nous constatons quelques problèmes. Veuillez nous contacter !"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["appelez-nous"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+32 3 375 60 70"])}
  },
  "tooltip": {
    "portal": {
      "webhooks": {
        "endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'URL qui reçoit la requête. Des outils tels que zapier.com ou typedhook.com fournissent des services de webhook à intégrer à votre CRM ou à d'autres outils d'automatisation."])},
        "secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le secret partagé est envoyé dans l'en-tête de la requête ('amplr-wh-secret'), utilisez-le pour vérifier si la requête entrante provient de amplr.cloud."])}
      }
    },
    "portalTab": {
      "allToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les clients connectés d’aujourd’hui, y compris ceux qui n’ont pas opté pour le marketing."])},
      "allTimeOptIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les clients qui ont opté pour le marketing depuis le début."])},
      "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des clients connectés de tous les temps, même ceux qui n'ont pas opté pour le marketing."])}
    }
  },
  "userProfile": {
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse de votre profil"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])}
    },
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un aperçu de votre profil"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])}
    },
    "security": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifiez les données sensibles ici"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données sécurisées"])}
    },
    "setupTwoFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer l'authentification à deux facteurs"])},
    "twoFactorScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scannez le code QR avec votre application d'authentification (Google Authenticator, 1Password, Bitwarden...). Vous ne pouvez pas scanner le code ? Copiez et collez l'URL !"])},
    "2fa": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurisez votre compte avec une authentification à deux facteurs"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification à deux facteurs"])},
      "finishButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer la configuration"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification à deux facteurs configurée avec succès."])},
      "codeValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour confirmer la configuration 2FA, veuillez confirmer en utilisant votre code 2FA nouvellement généré."])}
    },
    "socialLogin": {
      "googleActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'authentification Google est active. \nVous ne pouvez pas vous connecter avec un nom d'utilisateur et un mot de passe. \nSi vous souhaitez utiliser un nom d'utilisateur et un mot de passe pour vous connecter, détachez la connexion sociale."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion sociale"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous à la plateforme en utilisant la connexion sociale comme Google ou Microsoft."])},
      "microsoftActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La connexion Microsoft est active. \nVous ne pouvez pas vous connecter avec un nom d'utilisateur et un mot de passe. \nSi vous souhaitez utiliser un nom d'utilisateur et un mot de passe pour vous connecter, détachez la connexion sociale."])}
    },
    "twoFactorManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la manière dont vous souhaitez recevoir le code 2FA. \nVous pouvez choisir entre SMS ou mail."])},
    "sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurez-vous d'avoir un numéro de téléphone valide pouvant recevoir des messages texte (SMS). \nUn téléphone fixe ne fonctionnera pas !"])}
  },
  "users": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un utilisateur"])},
    "addAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un accès"])},
    "customer": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les entreprises"])},
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une entreprise"])}
    },
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les autorisations"])},
    "group": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les groupes"])},
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un groupe"])},
      "noGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas de groupes à choisir"])}
    },
    "location": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les emplacements"])},
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un emplacement"])}
    },
    "steps": {
      "access": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les groupes, entreprises ou emplacements auxquels vous voulez que l'utilisateur ait accès."])},
        "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser l'accès"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser l'accès utilisateur"])}
      },
      "button": {
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape suivante"])},
        "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape précédente"])}
      },
      "email": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez une ou plusieurs adresses e-mail des personnes que vous souhaitez ajouter."])},
        "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un e-mail"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des adresses e-mail"])}
      },
      "invite": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez les détails, si tout est correct, vous pouvez envoyer les invitations en cliquant sur le bouton."])},
        "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer une invitation"])}
      },
      "permissions": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les autorisations que vous souhaitez accorder à l'utilisateur."])},
        "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donner des droits"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donner des droits à"])}
      }
    },
    "table": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le lien"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien d'invitation"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
    },
    "title": {
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacements"])}
    },
    "type": {
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement"])}
    },
    "notification": {
      "lastPermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La suppression de la dernière autorisation de ce tableau entraînera la désactivation du compte sans aucun accès. \nSoyez prudent lorsque vous supprimez cette dernière autorisation !"])},
      "noAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun accès n'est défini pour cet utilisateur, veuillez ajouter un accès ou supprimer le compte utilisateur."])}
    }
  },
  "validations": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette adresse électronique n'est pas valide"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit comporter au moins 8 caractères, contenir une lettre majuscule, une lettre minuscule, un caractère spécial"])},
    "phoneValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce n'est pas un numéro de téléphone valide"])},
    "postalCodeValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce n'est pas un code postal valide"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire"])},
    "sameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce n'est pas une URL valide"])},
    "validVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce numéro de TVA n'est pas valide"])},
    "between": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le nombre doit être compris entre ", _interpolate(_named("min")), " et ", _interpolate(_named("max")), "."])},
    "ipExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette adresse IP est déjà prise. \nVeuillez choisir une autre adresse."])},
    "decimalDot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce n'est pas un format valide. \nUtilisez un point (.)."])}
  },
  "webhook": {
    "log": {
      "selectCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une demande pour afficher les détails"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal Webhook"])}
    }
  },
  "tenants": {
    "table": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
      "deactivateOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver sur"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les espaces)"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])}
    },
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
    "creating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendez! \nNous créons les locataires..."])},
    "deactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivé"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les locataires sont créés"])},
    "addTenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des locataires"])},
    "uploadCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer des utilisateurs à partir d'un fichier"])},
    "addManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter manuellement"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici."])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "editTenant": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le locataire"])},
      "chooseDeactivationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une date pour la désactivation future"])},
      "offboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivez les locataires en sélectionnant une date de désactivation ou cliquez sur le bouton Désactiver maintenant pour supprimer immédiatement l'accès d'un locataire."])},
      "scheduleNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà planifié la désactivation de ce locataire. \nAvez-vous fait cela par accident ou souhaitez-vous le supprimer?"])},
      "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la date prévue"])},
      "saveAndSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer et envoyer du courrier"])},
      "deactivateNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver maintenant ?"])}
    },
    "importComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons réussi l'importation."])},
    "amountImported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveaux locataires créés"])},
    "errorsOccured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs lors de la création"])},
    "selectSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruimte de sélectionneur"])},
    "edit": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changements sauvegardés avec succès."])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "amountPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les locataires seront créés ce soir"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création en attente"])}
  },
  "tenant": {
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scannez le code QR ou utilisez les informations suivantes pour vous connecter. \n(La numérisation du code QR n'enregistrera pas votre mot de passe sur votre appareil dans certains cas.)"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wifi"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bonjour ", _interpolate(_named("name")), ", vous trouverez ici vos informations de connexion pour le wifi sur"])},
    "csvInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importez un fichier CSV avec une ligne d'en-tête contenant deux colonnes : name, email. Sur votre deuxième ligne, vous ajoutez le nom dans la première colonne et l'e-mail dans la deuxième colonne. \nVous ne savez pas si vous faites cela correctement ? Téléchargez votre exemple CSV"])},
    "delete": {
      "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer ce locataire ? \nLa suppression d'un locataire supprimera tous les paramètres concernant le locataire. "])}
    },
    "create": {
      "form": {
        "forceCreate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer instantanément"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous utilisez Instant Create, le WiFi sur d'autres périphériques se déconnecteront rapidement. \nS'il n'y a pas d'urgence dans la création de la clé, veuillez ne pas utiliser cette fonction."])}
        }
      }
    }
  },
  "locationTypes": {
    "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maison"])},
    "horeca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horeca"])},
    "retail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vente au détail"])},
    "cohousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohabitation"])},
    "coworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coworking"])},
    "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôtel"])},
    "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bureau"])},
    "sports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation sportive"])}
  },
  "passwordConfirmModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer votre mot de passe"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez confirmer votre mot de passe avant de pouvoir continuer."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez le mot de passe"])}
  },
  "ppskNotConfigured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La gestion des locataires n'est pas activée. \nVeuillez contacter le support amplr pour vous aider à configurer cette fonctionnalité."])},
  "global": {
    "fiber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiber"])},
    "vdsl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDSL"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun"])}
  },
  "modal": {
    "confirm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es-tu sûr?"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez la suppression en saisissant :"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas entré la bonne valeur"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez confirmer votre action."])}
    },
    "save": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous quel nom veux-tu enregistrer cela ?"])}
    }
  },
  "mainNav": {
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les partenaires"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "app": {
      "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises"])},
      "b2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portail"])}
    },
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "admin": {
      "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
      "controllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôleurs"])},
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements"])},
      "inventory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventaire"])},
      "zerotier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau zéro"])}
    },
    "button": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])}
    },
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abo"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes"])},
    "refferal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus de parrainage"])},
    "referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prime de parrainage"])}
  },
  "partner": {
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le partenaire"])}
    },
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse du partenaire"])}
    },
    "access": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un accès"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un ou plusieurs groupes, clients ou emplacements auxquels le partenaire aura accès."])}
    }
  },
  "partnerProfile": {
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un aperçu de vos coordonnées"])}
    },
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos coordonnées"])}
    }
  },
  "referral": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites découvrir Amplr à d'autres entrepreneurs et bénéficiez d'une remise de 200 euros s'ils deviennent clients !"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez l'adresse e-mail, le nom et l'entreprise de la personne que vous souhaitez inviter. \nNous faisons le reste !"])},
    "sector": {
      "horeca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'industrie de la restauration"])},
      "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises"])},
      "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Événements"])},
      "retail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail"])}
    },
    "introduced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai déjà présenté amplr à cette personne"])}
  },
  "table": {
    "head": {
      "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du produit"])}
    }
  },
  "topology": {
    "deviceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'appareil"])},
    "device": {
      "ports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue d'ensemble des ports de commutation"])},
      "portName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de port"])},
      "selectPortProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le profil de port"])},
      "connectedDevice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le client <strong>(", _interpolate(_named("client")), ")</strong> est connecté sur le <strong>port ", _interpolate(_named("portNumber")), "</strong> de ce commutateur <strong>(", _interpolate(_named("switchName")), ")</strong> et possède l'IP suivante : <strong \n>", _interpolate(_named("clientIp")), "</strong>."])}
    },
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La modification d'un appareil peut avoir un impact sérieux sur le fonctionnement de votre réseau. \nSoyez prudent lorsque vous modifiez un paramètre."])}
  },
  "device": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'appareil"])},
    "uptime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilité de l'appareil"])},
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'appareil est hors ligne"])},
    "cellular": {
      "plmnError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement du fournisseur"])}
    }
  },
  "title": {
    "extraHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits supplémentaires"])}
  },
  "text": {
    "installationLog": {
      "extraHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des produits supplémentaires ont-ils été placés qui ne sont pas inclus dans l'accord ? \nRecherchez les produits, puis ajoutez-les."])}
    }
  },
  "financials": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif financier 2023"])}
  },
  "tag": {
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payé"])},
    "unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non payé"])},
    "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En retard"])}
  },
  "user": {
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter un utilisateur"])}
  },
  "details": {
    "247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h/24, 7j/7"])},
    "mailSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance par e-mail"])},
    "phoneSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance téléphonique"])},
    "businessHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaires: Lun à Ven de 9h à 17h"])},
    "businessHoursPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaires: Lun à Ven de 8h à 20h"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès au dashboard"])},
    "dashboardBasic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès de base au dashboard"])},
    "supportQR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR d'assistance"])},
    "maxDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appareils maximum : "])},
    "brandedGuestWifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wifi personnalisé"])},
    "deviceUpdates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mises à jour automatiques des appareils"])}
  },
  "successpage": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous voyez cette page, votre connexion Internet fonctionne."])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous client AMPLR?"])},
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès!"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes pas encore client et vous souhaitez plus d'informations ?"])}
  },
  "toast": {
    "restored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restauré"])},
    "exportStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'exportation a commencé. Vous recevrez un e-mail lorsque l'exportation sera prête à être téléchargée."])}
  },
  "select": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseurs"])}
  },
  "public": {
    "noDeviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe système n'est encore défini."])}
  },
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "past_due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En souffrance"])},
  "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])},
  "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connexion Internet"])},
  "failover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculement"])},
  "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan de services"])},
  "peplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence Peplink"])},
  "serviceError": {
    "verifyProductBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le produit diffère de la facturation. \nVérifiez l'emplacement et/ou la facturation !"])},
    "installedButNotBilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le service est installé mais pas facturé ! \nVeuillez ajuster la facturation."])},
    "billedButNotActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit facturé mais non actif sur place. \nVeuillez vérifier les paramètres de localisation !"])}
  },
  "message": {
    "noSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'emplacement n'a pas d'abonnement actif. \nVeuillez vérifier auprès de la comptabilité."])},
    "sales": {
      "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visite de vente ajoutée !"])}
    }
  },
  "sales": {
    "new": {
      "venueDetails": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du lieu"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnez les détails du lieu."])}
      },
      "contactDetails": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du contact"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez les coordonnées"])}
      },
      "details": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le lieu"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'infos sur le lieu"])}
      },
      "extra": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplémentaire"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations supplémentaires"])}
      },
      "generalInfo": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["informations générales"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez la demande d'informations générales"])}
      }
    }
  },
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "filter": {
    "sales": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
      "wantsOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veut un devis"])},
      "followUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'un suivi"])}
    }
  },
  "search": {
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat! \nVeuillez utiliser un autre terme de recherche."])}
  },
  "filters": {
    "hasNoPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans mode de paiement"])},
    "hasNoLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans emplacements"])}
  },
  "customers": {
    "noAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun droit d'accès pour ce client"])},
    "invoices": {
      "forceReload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forcer le rechargement des factures"])}
    }
  },
  "main": {
    "referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prime"])}
  },
  "bonus": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&#127873; Référez-vous et économisez sur votre prochaine facture !"])},
      "howDoesItWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment ça marche?"])},
      "howDoesItWorkBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connaissez-vous des entreprises ou des particuliers au sein de votre réseau qui pourraient être intéressés par les services d'Amplr ? \nSi tel est le cas, vous pouvez désormais nous les référer facilement. \nTout ce que vous avez à faire est de susciter l’enthousiasme du client pour nos solutions. \nNous nous occuperons du reste !"])},
      "whatsInIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qu'est-ce que cela vous apporte ?"])},
      "whatsInItForYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour vous remercier de vos efforts, nous avons mis en place un programme de récompense pour les parrainages réussis. \nSi votre prospect conclut un accord avec Amplr, vous bénéficierez d'une remise de 200 € sur votre prochaine facture. \nDe plus, votre prospect parrainé bénéficiera d'une réduction intéressante sur les frais d'installation uniques."])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class=\"mb-3\">Chez Amplr, nous pensons que chaque entreprise a droit à une connexion Internet fiable. \nC'est pourquoi nous nous engageons constamment à améliorer nos services et à élargir notre clientèle. \n</p><p class=\"mb-3\">En tant que client apprécié d'Amplr, nous comprenons que votre réseau et vos connexions uniques sont d'une importance inestimable. \n</p><p>Par conséquent, nous vous invitons à explorer avec nous de nouveaux emplacements qui, comme le vôtre, peuvent bénéficier de nos solutions via le programme de parrainage Amplr.</p>"])}
    },
    "page": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de référence"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez le formulaire pour recommander une entreprise pouvant utiliser Amplr pour la connectivité."])}
    }
  },
  "signal": {
    "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien"])},
    "fair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équitable"])},
    "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pauvre"])},
    "unstable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instable"])}
  },
  "clients": {
    "table": {
      "tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locataire"])}
    },
    "reservedClients": {
      "noClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun client configuré"])}
    },
    "reservations": {
      "ipExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un client avec cette IP est déjà configuré"])},
      "macAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un client avec cette adresse MAC est déjà configuré. \nVous devez d'abord supprimer la réservation pour en créer une nouvelle."])}
    }
  },
  "unifios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unifi OS"])},
  "omada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TP-Link Omada"])},
  "unificloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud Controller"])},
  "controller": {
    "loginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super! \nNous pouvons joindre votre contrôleur."])},
    "connectionFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La connexion avec votre contrôleur a échoué. \nVeuillez vous assurer que votre contrôleur est accessible depuis l'extérieur du réseau."])},
    "wrongCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous ne pouvons pas effectuer d'appel de connexion au contrôleur. \nVeuillez vous assurer que le nom d'utilisateur et le mot de passe sont corrects."])},
    "createSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôleur ajouté avec succès"])}
  },
  "branding": {
    "settings": {
      "form": {
        "headerImage": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image d'en-tête"])}
        },
        "logo": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])}
        }
      }
    },
    "mail": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres personnalisés pour les mails"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisez vos e-mails en utilisant des couleurs personnalisées, une image d'en-tête et un logo."])}
    }
  },
  "image": {
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous traitons votre image"])}
  },
  "deviceGroups": {
    "loadingDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement de tous les appareils connectés..."])}
  },
  "connectionLoss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet tombe régulièrement"])},
  "slowWifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi lent/wifi invité"])},
  "posIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problèmes de point de vente (tickets/connexion...)"])},
  "supportIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le support du fournisseur est de la merde"])},
  "soundIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bégaiement sonore"])},
  "followUp": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire un devis"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer un appel"])},
    "notNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas maintenant"])}
  },
  "copy": {
    "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier dans le presse-papier"])}
  },
  "invalid": {
    "apiKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La clé API semble invalide. \nVeuillez vérifier la clé et réessayer."])}
  },
  "app": {
    "dashboardTab": {
      "noDeviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe de périphériques configuré."])}
    }
  },
  "product": {
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le produit n'existe pas"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le produit n'existe pas"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit mis à jour"])}
  },
  "productModal": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle de produit introuvable"])}
  },
  "productModel": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle de produit introuvable"])}
  },
  "fluvius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fluvius"])},
  "errorCreatingOmadaLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la création de l'emplacement Omada. \nLe fichier n'est pas compatible. \nVeuillez vous assurer d'utiliser le bon fichier (dans les paramètres de configuration) correspondant à la version du contrôleur."])},
  "loadingFull": {
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assembler le tableau de bord..."])}
  },
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement..."])},
  "page": {
    "title": {
      "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégrations"])}
    }
  },
  "site": {
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appareil(s)"])}
  },
  "profile": {
    "twoFa": {
      "selectMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un mode de livraison"])},
      "smsMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS ou courrier"])},
      "authenticator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentificateur TOTP"])}
    }
  },
  "topbar": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueillir"])},
    "message": {
      "twoFa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurez l'authentification à deux facteurs (2FA) pour votre compte. \nFais-le"])}
    }
  },
  "label": {
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])}
  }
}