import http from './axios';

class TenantService {
  getTenants(id) {
    return http.get(`/locations/${id}/network/tenants`);
  }

  deleteTenant(id, tenantId) {
    return http.delete(`/locations/${id}/network/tenants/${tenantId}`);
  }

  createTenants(id, payload, type) {
    return http.post(`/locations/${id}/network/tenants/`, payload, {
      headers: {
        'Content-Type':
          type === 'formdata' ? 'multipart/form-data' : 'application/json',
      },
    });
  }

  mailTenant(id, tenantId) {
    return http.get(`/locations/${id}/network/tenants/${tenantId}/mail`);
  }

  getPublicTenant(id) {
    return http.get(`/public/tenants/${id}`);
  }

  editTenant(id, tenantId, payload) {
    return http.patch(`/locations/${id}/network/tenants/${tenantId}/`, payload);
  }

  getPendingTenants(id) {
    return http.get(`/locations/${id}/network/pendingtenants`);
  }

  deletePendingTenant(id, tenantId) {
    return http.delete(`/locations/${id}/network/pendingtenants/${tenantId}`);
  }

  updatePendingTenant(id, tenantId) {
    return http.patch(`/locations/${id}/network/pendingtenants/${tenantId}`);
  }
}

export default new TenantService();
